import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { useStatutsStore } from '~~/stores/statuts';
import {
  CommandeStatus,
  FactureClientStatus,
  JalonFacturationFixe,
  JalonsProjet,
  MetreStatus,
  MetreTravauxStatus,
  ParametreKeys,
  TablesNames,
  TypeConstructeur,
} from '~~/types/Enums';
import { useFiltersStore } from './filters';
import { useMetresStore } from './metres';
import { useParametresStore } from './parametres';
import { useContratsStore } from './contrats';
import { useEcheanciersStore } from './echeanciers';
import { useChantiersStore } from './chantiers';
import { useCommandesStore } from './commandes';
import { useInformationsMarqueStore } from './informationsMarque';
import { usePvTtcAvenantsSignes } from '~/composables/contrats/usePvTtcAvenantsSignes';
import { useDernierPrixConnuTtc } from '~/composables/contrats/useDernierPrixConnuTtc';
import { useDernierPrixConnuHt } from '~/composables/contrats/useDernierPrixConnuHt';
import { usePermisStore } from './permis';
import { useTerrainsProjetsStore } from './terrainsProjets';
import { useFinancementsStore } from './financements';
import { useCcisStore } from './ccis';
import { useEpesStore } from './epes';
import { useGarantiesStore } from './garanties';
import { usePrepaschantiersStore } from './prepaschantiers';
import { useFacturationFacturesReglees } from '~/composables/contrats/facturation/useFacturationFacturesReglees';
import { useFacturationFacturesEnAttentes } from '~/composables/contrats/facturation/useFacturationFacturesEnAttentes';

const emptyProjet = () => ({
  commercial: null,
  adresse_ligne: '',
  adresse_cp: '',
  adresse_commune: '',
  notes: '',
  clients: [],
});

export const useProjetsStore = defineStore({
  id: 'projets-store',
  state: () => {
    return {
      collection: [],
      clientForProjet: null,
      currentProjet: null,
      currentAvp: undefined,
      editedProjet: emptyProjet(),
      creationPanelOpened: false,
      page: 1,
      pageCount: 1,
      sortField: 'createdAt',
      sortDirection: ':desc',
      query: '',
      filters: {},
      api: useApi().projets,
      jalons: [],
      refreshCodeProjet: false,
      sstfourPanelOpen: false,
    };
  },
  actions: {
    async fetchProjets(limit = 25) {
      const response = await this.api.find(
        this.page,
        this.querySorts,
        this.query,
        limit,
        this.queryFilters,
        {
          avancement: { populate: { statut: true } },
          clients: {
            populate: {
              agence: true,
            },
          },
          commercial: true,
          dessinateur: true,
          contrat: true,
          prepachantier: true,
          chantier: {
            populate: {
              conduc: true,
            },
          },
          avps: {
            sort: 'id:desc',
            populate: {
              metre: {
                populate: {
                  metreur: true,
                },
              },
            },
          },
        },
      );

      return response;
    },

    async fetch() {
      this.filters = await this.api.getFilters();
      const response = await this.fetchProjets();

      this.collection = response?.data || [];
      this.page = response?.meta?.pagination?.page || 1;
      this.pageCount = response?.meta?.pagination?.pageCount || 1;
    },

    async refresh() {
      await this.fetch();
    },

    async fetchOne(id) {
      const res = await this.api.findOne(id);
      if (res) {
        this.setCurrentProjet(res.data);

        const promises = this.currentProjet?.contrat?.dateSignature
          ? [
              await useContratsStore().fetch(),
              await usePermisStore().fetch(),
              await useTerrainsProjetsStore().fetch(),
              await useFinancementsStore().fetch(),
              await useCcisStore().fetch(),
              await useEpesStore().fetch(),
            ]
          : [];
        await Promise.all(promises);
      }
      return res;
    },

    async create(item) {
      item.code = await this.getNewProjetCode(item);
      this.refreshCodeProjet = false;
      const res = await this.api.create(item);
      return res;
    },

    async update(item) {
      if (this.refreshCodeProjet) {
        item.code = await this.getNewProjetCode(item);
        this.refreshCodeProjet = false;
      }
      const res = await this.api.update(item);
      return res;
    },

    async cancel(id) {
      const res = await this.api.cancel(id);
      return res;
    },

    async delete(id) {
      const res = await this.api.delete({ id });
      return res;
    },

    async refreshCurrent() {
      if (!this.currentProjet) return;
      const res = await this.api.findOne(this.currentProjet.id);
      this.setCurrentProjet(res.data);
      return res;
    },

    async createNewAvp(duplicate = false, documents = []) {
      const navp = { projet: { id: this.currentProjet.id }, duplicate };
      if (documents.length) {
        navp.documents = documents.map((d) => d.id);
      }
      const avp = await useApi().avps.create(navp);
      if (avp) {
        const projetAvancement = avp.metre
          ? useConstants.avancements.FP_EN_COURS
          : useConstants.avancements.DEMANDE_AVP;
        await this.moveTo(avp.projet.id, projetAvancement);

        await this.refreshCurrent();
        this.setCurrentAvp(undefined);
      }
    },

    async getProjetsForCommandes() {
      const res = await useApi().projets.find(1, 'createdAt:desc', '', 100, {
        $and: [{ metre_travaux: { statut: { $eq: MetreTravauxStatus.VALIDE } } }],
      });
      return res && res.data ? res.data : [];
    },

    async sendDemandeNd(avp) {
      await this.moveTo(avp.projet.id, useConstants.avancements.ND_DEMANDEE);
      await useMetresStore().freeMetre(avp.metre.id);
      await useMetresStore().moveTo(avp.metre, MetreStatus.EN_COURS, {
        action: 'Demande de ND',
        date: DateTime.now().toISO(),
        version: avp.version,
      });

      await this.refreshCurrent();
    },

    async moveTo(id, avancement) {
      const res = await this.api.moveTo(id, avancement);
      if (res) {
        await this.refreshCurrent();
      }
      return res;
    },

    setRefreshCodeProjet(value) {
      this.refreshCodeProjet = value;
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query) {
      this.page = 1;
      this.query = query;
    },

    setClientForProjet(client) {
      this.clientForProjet = client;
    },

    setCurrentProjet(projet) {
      this.currentProjet = projet;
    },

    setEditedProjet(projet) {
      this.editedProjet = projet;
    },

    setCreationPanelOpened(value) {
      this.creationPanelOpened = value;
      if (!value) {
        this.setEditedProjet(emptyProjet());
        this.setClientForProjet(null);
      }
    },

    createNewProjet() {
      this.setEditedProjet(emptyProjet());
      this.setCreationPanelOpened(true);
    },

    editProjet(projet) {
      this.setEditedProjet(projet);
      this.setCreationPanelOpened(true);
    },

    async fetchJalons() {
      if (!this.currentProjet) return [];
      this.jalons = await this.api.jalons(this.currentProjet.id);
      return this.jalons;
    },

    async getNewProjetCode(projet) {
      const nextNumber = await this.api.getNextProjetNum(projet.clients.map((c) => c.id));
      const leftPart = projet.clients.reduce((p, c) => {
        return `${p}${p ? '-' : ''}${useSlug(`${c.raisonSociale || c.nom}`).toUpperCase() || ''}`;
      }, '');
      const rightPart = !!nextNumber && nextNumber > 1 ? `_${nextNumber}` : '';
      return `${leftPart}${rightPart}`;
    },

    projetAvancementIs(projet, orderSymbole, avancementCode) {
      if (!projet || !projet.avancement) return false;
      const allAvancements = useStatutsStore().allAvancements;
      const avancementIndex = allAvancements.findIndex((a) => a.code === avancementCode);
      const projetAvancementIndex = allAvancements.findIndex(
        (a) => a.code === projet.avancement.code,
      );

      if (avancementIndex === -1 || projetAvancementIndex === -1) return false;

      switch (orderSymbole) {
        case '<':
          return projetAvancementIndex < avancementIndex;
        case '<=':
          return projetAvancementIndex <= avancementIndex;
        case '>':
          return projetAvancementIndex > avancementIndex;
        case '>=':
          return projetAvancementIndex >= avancementIndex;
        case '==':
          return projetAvancementIndex === avancementIndex;
        default:
          return false;
      }
    },

    checkJalons(jalons) {
      return this.checkJalonsStatus(jalons);
    },

    checkJalonsStatus(jalons, status = [-1, 1]) {
      const jalonsProjet = this.jalons;
      const jalonsToCheck = jalonsProjet.filter((j) => jalons.includes(j.name));
      const jalonsValid = jalonsToCheck.filter((j) => status.includes(j.status));
      return jalonsValid.length === jalons.length;
    },

    isBefore(avCode) {
      return this.projetAvancementIs(this.currentProjet, '<', avCode);
    },

    isAfter(avCode) {
      return this.projetAvancementIs(this.currentProjet, '>=', avCode);
    },

    isBetween(avCode1, avCode2) {
      return this.isAfter(avCode1) && this.isBefore(avCode2);
    },

    async checkAndMoveForward() {
      const avts = useConstants.avancements;
      const cStore = useContratsStore();

      if (this.isBetween(avts.VALIDATION_ADV, avts.RAR)) {
        if (cStore.canMoveToRar) await this.moveTo(this.currentProjet.id, avts.RAR);
      }
      if (this.isBetween(avts.RAR, avts.LEVEE_COND_SUSP)) {
        if (cStore.canMoveToLeveCondSusp)
          await this.moveTo(this.currentProjet.id, avts.LEVEE_COND_SUSP);
      }
      if (this.isBetween(avts.LEVEE_COND_SUSP, avts.PREPA_CHANTIER)) {
        if (
          this.checkJalons([
            JalonsProjet.PC,
            JalonsProjet.FINANCEMENT,
            JalonsProjet.ACQUISITION_TERRAIN,
            JalonsProjet.CCI,
          ])
        ) {
          await this.moveTo(this.currentProjet.id, avts.PREPA_CHANTIER);
        }
      }

      if (this.projetAvancementIs(this.currentProjet, '==', avts.PREPA_CHANTIER)) {
        const jalonsTravaux = [
          JalonsProjet.DROC,
          JalonsProjet.FINANCEMENT,
          JalonsProjet.PLANS_EXECUTION_SIGNE,
          JalonsProjet.ACQUISITION_TERRAIN,
          JalonsProjet.CONTRAT,
          JalonsProjet.METRETRAVAUX,
        ];

        if (useInformationsMarqueStore().typeConstructeur === TypeConstructeur.CCMI) {
          jalonsTravaux.push(JalonsProjet.GARANTIES);
        }

        if (this.checkJalons(jalonsTravaux)) {
          await this.moveToTravaux();
        }
      }
    },

    async moveToTravaux() {
      const premiereEcheance = this.currentProjet?.echeancier?.echeances[0];
      if (!this.currentProjet.chantier.codeJalonChantier)
        this.currentProjet.chantier.codeJalonChantier = premiereEcheance?.code || '';
      const commandes = await this.getCommandesForProjet(this.currentProjet.id);
      const deboursOuverture = commandes
        .filter((c) => c.statut !== CommandeStatus.ANNULEE)
        .reduce((acc, c) => acc + useCommandesStore().montantTotalHt(c), 0);
      const dpc = useDernierPrixConnuHt(this.currentProjet.contrat);
      const margeOuverture = useCalcMarge(dpc, deboursOuverture);
      const newAnalyticsDatas = this.currentProjet.analyticsDatas || {};
      newAnalyticsDatas.margeOuverture = margeOuverture;
      newAnalyticsDatas.deboursOuverture = deboursOuverture;
      this.currentProjet.analyticsDatas = newAnalyticsDatas;
      await this.update({ id: this.currentProjet.id, analyticsDatas: newAnalyticsDatas });
      await useChantiersStore().update({
        id: this.currentProjet.chantier.id,
        codeJalonChantier: this.currentProjet.chantier.codeJalonChantier,
      });
      await this.moveTo(this.currentProjet.id, useConstants.avancements.TRAVAUX);
    },

    reset() {
      this.currentProjet = null;
      this.currentAvp = undefined;
      this.jalons = [];
      useContratsStore().reset();
      usePermisStore().reset();
      useTerrainsProjetsStore().reset();
      useFinancementsStore().reset();
      useCcisStore().reset();
      useEpesStore().reset();
      useGarantiesStore().reset();
      usePrepaschantiersStore().reset();
      useChantiersStore().reset();
      useCommandesStore().reset();
    },

    async getCommandesForProjet(projetId) {
      const res = await useApi().commandes.find(1, 'createdAt:desc', '', 100, {
        projet: { id: { $eq: projetId } },
      });

      return res && res.data ? res.data : [];
    },

    toggleSSttfourPanel() {
      this.sstfourPanelOpen = !this.sstfourPanelOpen;
    },

    createFacturationForProjet(projet) {
      const dernierPrixConnu = useDernierPrixConnuTtc(projet.contrat);
      const echeancier = projet.echeancier;

      if (!echeancier) return null;

      const echeances = echeancier.echeances;
      const factures = [];

      if (projet.contrat.montantAcompte > 0) {
        const facture = {
          id: crypto.randomUUID(),
          reference: `XX-${projet.contrat.reference}-ACMPT`,
          codeJalon: JalonFacturationFixe.ACOMPTE,
          pourcentJalon: -1,
          dernierPrixConnu: null,
          montantTheorique: projet.contrat.montantAcompte,
          montantTotal: projet.contrat.montantAcompte,
          date: '',
          reglements: [],
          statut: FactureClientStatus.A_GENERER,
          montantDo: 0,
        };
        factures.push(facture);
      }

      for (let i = 0; i < echeances.length; i++) {
        const echeance = echeances[i];

        const montant = useRound(
          dernierPrixConnu * (echeance.pourcentage / 100) -
            factures.reduce((acc, f) => acc + f.montantTheorique, 0),
        );

        const facture = {
          id: crypto.randomUUID(),
          reference: `XX-${projet.contrat.reference}-${echeance.code}`,
          codeJalon: echeance.code,
          pourcentJalon: echeance.pourcentage,
          dernierPrixConnu: null,
          montantTheorique: montant,
          montantTotal: montant,
          date: '',
          reglements: [],
          statut: FactureClientStatus.A_GENERER,
          montantDo: echeance.rembDO ? projet?.garantie?.montantDo || 0 : 0,
        };
        factures.push(facture);
      }

      const facturation = {
        totalReglement: 0,
        totalReglementEnAttente: 0,
        nomDestinataires: this.nomsDestinatairesProjet(projet),
        adresseDestinataires: this.adresseDestinatairesProjet(projet),
        factures,
      };

      projet.facturation = facturation;

      return facturation;
    },

    montantFactureAuJalon(facture, dpc) {
      if (facture.codeJalon === JalonFacturationFixe.ACOMPTE)
        return facture.date ? facture.montantTheorique : this.currentProjet.contrat.montantAcompte;

      const echeance = useEcheanciersStore().getEcheanceByCode(
        facture.codeJalon,
        this.currentProjet.echeancier,
      );

      const pourcentage = facture.date ? facture.pourcentJalon : echeance?.pourcentage || 0;
      return useRound(dpc * (pourcentage / 100));
    },

    montantTheoriqueFacture(facture, dpc, facturation) {
      if (facture.date) return facture.montantTheorique;

      const fIndex = facturation?.factures?.findIndex((f) => f.id === facture.id) || null;

      if (fIndex < 0) return 0;

      let result = this.montantFactureAuJalon(facture, dpc);

      for (let i = fIndex - 1; i >= 0; i--) {
        const theFactureBefore = facturation.factures[i];
        result =
          result -
          (theFactureBefore.date
            ? theFactureBefore.montantTheorique
            : this.montantTheoriqueFacture(theFactureBefore, dpc, facturation));
      }
      return useRound(result);
    },

    montantTotalFacture(facture, dpc, facturation) {
      if (facture.date) return facture.montantTotal;
      const montantTheorique = facture.date
        ? facture.montantTheorique
        : this.montantTheoriqueFacture(facture, dpc, facturation);
      const resteARegler = facturation.totalReglementEnAttente;
      return useRound(montantTheorique + resteARegler);
    },

    montantTotalResteAFacturer(facturation) {
      const dpc = useDernierPrixConnuTtc(this.currentProjet.contrat);
      const factureDejaGenerees = facturation.factures.filter((f) => !!f.date);
      const montantsTheoriques = factureDejaGenerees.reduce((acc, f) => {
        acc += this.montantTheoriqueFacture(f, dpc, facturation);
        return acc;
      }, 0);

      return useRound(dpc - montantsTheoriques);
    },

    setFactureValues(facture, date) {
      facture.date = '';
      const echeance = useEcheanciersStore().getEcheanceByCode(
        facture.codeJalon,
        this.currentProjet.echeancier,
      );

      const lastPnl = useContratsStore().getLastPnlProjet(this.currentProjet.contrat);
      const matriceTva = lastPnl?.pvsHt || [];

      facture.matriceTva = matriceTva;

      facture.pourcentJalon = echeance?.pourcentage || 0;
      facture.dernierPrixConnu = useDernierPrixConnuTtc(this.currentProjet.contrat);
      facture.montantTheorique = this.montantTheoriqueFacture(
        facture,
        facture.dernierPrixConnu,
        this.currentProjet.facturation,
      );
      facture.montantTotal = this.montantTotalFacture(
        facture,
        facture.dernierPrixConnu,
        this.currentProjet.facturation,
      );
      facture.date = date;
      facture.statut = FactureClientStatus.A_ENVOYER;
      const anneeDate = DateTime.fromISO(date).toFormat('yy');
      facture.reference = facture.reference.replace('XX', anneeDate);
      facture.montantResteAFacturer = this.montantTotalResteAFacturer(
        this.currentProjet.facturation,
      );
      facture.montantReglementsEnAttente = this.currentProjet.facturation.totalReglementEnAttente;
      facture.montantTotalAuJalon = this.montantFactureAuJalon(facture, facture.dernierPrixConnu);
      facture.montantAvenantsSignes = usePvTtcAvenantsSignes(this.currentProjet.contrat);
      facture.montantTotalReglements = this.currentProjet.facturation?.totalReglement;

      const factTierse = this.currentProjet.facturation.facturationTierce;

      facture.nomDestinataires = factTierse
        ? this.currentProjet.facturation.nomDestinataires
        : this.nomsDestinatairesProjet(this.currentProjet);
      facture.adresseDestinataires = factTierse
        ? this.currentProjet.facturation.adresseDestinataires
        : this.adresseDestinatairesProjet(this.currentProjet);

      facture.facturesReglees = useFacturationFacturesReglees(
        facture,
        this.currentProjet.facturation,
        true,
      );

      facture.facturesEnAttentes = useFacturationFacturesEnAttentes(
        facture,
        this.currentProjet.facturation,
        true,
      );
    },

    setFactureStatut(facture, statut) {
      facture.statut = statut;
    },

    async updateFacturation(projet) {
      await this.update({ id: projet.id, facturation: projet.facturation });
    },

    getAvancementsFromEcheancier(projet) {
      if (!projet.echeancier) return [];
      const echeancier = projet.echeancier;
      const echeances = echeancier.echeances;
      const avancements = [];

      for (let i = 0; i < echeances.length; i++) {
        const echeance = echeances[i];
        if (!echeance.isTravaux) continue;
        const avancement = {
          code: echeance.code,
          libelle: echeance.libelle,
          description: '',
          statut: projet.avancement.statut,
          ordre: i,
        };

        avancements.push(avancement);
      }

      return avancements;
    },

    setCurrentAvp(avp) {
      this.currentAvp = avp;
    },
  },
  getters: {
    isCanceled: () => (projet) => projet.avancement.code === useConstants.avancements.ANNULE,

    isEditable: (state) => (projet) => {
      return state.projetAvancementIs(projet, '<=', useConstants.avancements.TRAVAUX);
    },

    lastAvp: (state) => {
      return state.currentProjet?.avps && state.currentProjet.avps?.length
        ? state.currentProjet.avps[0]
        : null;
    },

    currentMetre: (state) => {
      return state.lastAvp?.metre;
    },

    avp() {
      return this.currentAvp || this.lastAvp;
    },

    queryFilters: () => useFiltersStore().queryFilters(TablesNames.PROJETS),

    querySorts: () => useDatasTablesState(TablesNames.PROJETS).querySorts(),

    isProjetSousEngagementNotice: () => (projet) => {
      let sousEngagement = false;
      const contrat = projet?.contrat;

      if (contrat && contrat.date_signature) {
        const periodeEngagement = parseInt(
          useParametresStore().paramByKey(ParametreKeys.DUREE_ENGAGEMENT_NOTICE),
        );

        const dateSignature = DateTime().fromISO(contrat.date_signature);
        const dateEngagement = dateSignature.plus({ days: periodeEngagement });
        const dateNow = DateTime().now();
        sousEngagement = dateEngagement > dateNow;
      }
      return sousEngagement;
    },

    bt01: (state) => {
      if (!state.currentProjet?.contrat?.BT01) return { dayLeft: 0, date: null };
      const date = DateTime.fromISO(state.currentProjet.contrat.BT01);
      const dayLeft = Math.round(date.diff(DateTime.now(), 'days').days);

      return { dayLeft, date: useDateFormat(state.currentProjet.contrat.BT01) };
    },

    statuts: () => {
      return useStatutsStore().collection;
    },

    projetHasOneFactureWithDate(state) {
      return (projet) => {
        const p = projet || state.currentProjet;
        return p?.facturation?.factures?.some((f) => !!f.date) || false;
      };
    },

    nomsDestinatairesProjet() {
      return (projet) => {
        return (
          projet?.clients
            ?.map(
              (c) =>
                (c.type === useConstants.clientTypes.PRO
                  ? c.formeJuridique + ' ' + c.raisonSociale + '\n'
                  : '') +
                c.civilite +
                ' ' +
                c.nom +
                ' ' +
                c.prenom,
            )
            .join('\n') || ''
        );
      };
    },

    adresseDestinatairesProjet() {
      return (projet) => {
        return projet?.clients?.length
          ? projet.clients[0].adresse_ligne +
              '\n' +
              projet.clients[0].adresse_cp +
              ' ' +
              projet.clients[0].adresse_commune
          : '';
      };
    },
  },
});

import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Garantie } from '~/types/models/garantie';
import type { Garant } from '~/types/models/garant';

export const useGarantiesStore = defineStore('garanties', {
  state: () => ({
    garantie: null as Garantie | null,
    garants: [] as Garant[],
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi().garanties.findOne(this.currentProjet.garantie.id);
        if (res && res.data) {
          this.garantie = res.data;
        }
      }
    },

    async fetchGarants() {
      const res = await useApi().garants.find(1, '', '', 100);
      if (res && res.data) {
        this.garants = res.data;
      }
    },

    async create(garantie: Creatable<Garantie>) {
      const res = await useApi().garanties.create(garantie);
      if (res && res.data) {
        this.garantie = res.data;
      }
    },

    async update(garantie: Updatable<Garantie>) {
      const res = await useApi().garanties.update(garantie);
      if (res && res.data) {
        this.garantie = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.garantie) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.garantie = null;
    },
  },
});

import type { LigneMetre } from '~/types/models/ligneMetre';
import { usePvHtLigne } from './usePvHtLigne';

export const usePvTtcLignes = (lignes: LigneMetre[]) => {
  const acc = {
    pvHt: 0,
    pvHtParTaux: {} as Record<number, number>,
  };
  const recap = lignes.reduce((acc, l) => {
    acc.pvHt += usePvHtLigne(l);
    if (l.tva) {
      if (!acc.pvHtParTaux[l.tva]) acc.pvHtParTaux[l.tva] = 0;
      acc.pvHtParTaux[l.tva] += usePvHtLigne(l);
    }
    return acc;
  }, acc);

  let pvttc = 0;

  for (const t in recap.pvHtParTaux) {
    pvttc += recap.pvHtParTaux[t] * useTauxTva(parseFloat(t));
  }

  return pvttc;
};

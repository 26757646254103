<template>
  <textarea
    ref="theInput"
    :value="sanitizedValue"
    :name="id"
    :id="id"
    autocomplete="off"
    :rows="rows || 5"
    @change="onChange"
    @input="onInput"
    cols="500"
    class="input whitespace-wrap"
    :class="{ 'overflow-hidden': !scrollable, 'resize-none': !resizable }"
    v-focus
    :placeholder="placeholder" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';

type Props = {
  modelValue?: string | null;
  id?: string;
  scrollable?: boolean;
  autogrow?: boolean;
  resizable?: boolean;
  focus?: boolean;
  placeholder?: string;
  debounced?: boolean;
  rows?: number;
};

const vFocus = {
  mounted: (el: HTMLElement) => props.focus && el.focus(),
};

const props = withDefaults(defineProps<Props>(), {
  scrollable: false,
  resizable: false,
  debounced: true,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const input = computed({
  get() {
    return props.modelValue || '';
  },
  set(value: string | null) {
    emit('update:modelValue', value ? useSanitizeUserTextInput(value) : '');
  },
});

watch(
  () => input.value,
  () => {
    if (input.value)
      nextTick(() => {
        resize();
      });
  },
  { immediate: true },
);

onMounted(() => {
  resize();
});

const sanitizedValue = computed(
  () =>
    props.modelValue?.replace(
      /[^\w\s.,!?;:()'"-_\/\\\[\]|ÀàÉéÈèÊêËëÎîÏïÔôÙùÛûüÇçâØ²=%ù£€\*`œ{}<>\^&°·]/gi,
      '',
    ) || '',
);

const onChange = (event: Event) => {
  if (props.debounced) {
    deboundUpdateInputValue(event);
  } else {
    updateInputValue(event);
  }
};

const updateInputValue = (event: Event) => {
  input.value = (event.target as HTMLInputElement).value;
};

const deboundUpdateInputValue = useDebounceFn(updateInputValue, 1000);

const theInput = ref<HTMLTextAreaElement | null>(null);

const { scroller } = storeToRefs(useAppStore());

const resize = (event?: Event) => {
  const targetValue = (event?.target as HTMLTextAreaElement)?.value || theInput.value?.value;

  if (theInput.value && props.autogrow && targetValue) {
    // record scroll position
    const y = scroller.value?.scrollTop;

    theInput.value.style.height = 'auto';
    theInput.value.style.height = `${theInput.value.scrollHeight}px`;

    // restore scroll position
    scroller.value?.scrollTo({
      top: y,
      left: 0,
    });
  }
};

const onInput = (event: Event) => {
  resize(event);
  deboundUpdateInputValue(event);
};
</script>

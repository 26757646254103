import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Permis } from '~~/types/models/permis';
import { DocumentCategory } from '~/types/Enums';

export const usePermisStore = defineStore('permis', {
  state: () => ({
    permis: null as Permis | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },

    mandatoryDocs() {
      return useDocumentTypes()[DocumentCategory.PC].filter((d) => d.required);
    },

    missingDocuments: (state) => {
      const mandatoryDocs = useDocumentTypes()[DocumentCategory.PC].filter((d) => d.required);
      const result = [];

      for (const doc of mandatoryDocs) {
        const docs = state.permis?.documents?.filter((d) => d.type === doc.name);
        if (!docs || docs.length < 1) {
          result.push(doc);
        }
      }
      return result;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi().permis.findOne(this.currentProjet.pdc.id);
        if (res && res.data) {
          this.permis = res.data;
        }
      }
    },

    async create(permis: Creatable<Permis>) {
      const res = await useApi().permis.create(permis);
      if (res && res.data) {
        this.permis = res.data;
      }
    },

    async update(permis: Updatable<Permis>) {
      const res = await useApi().permis.update(permis);
      if (res && res.data) {
        this.permis = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.permis) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    async depotPc(date: string, delai: number) {
      if (this.permis)
        await this.update({
          id: this.permis.id,
          dateDepot: date,
          delaiInstruction: delai,
        });
    },

    async depotDemandePiecesComplementaires(date: string) {
      if (this.permis)
        await this.update({
          id: this.permis.id,
          dateDemandePieceComplementaire: date,
        });
    },

    async reponseDemandePiecesComplementaires(date: string) {
      if (this.permis)
        await this.update({
          id: this.permis.id,
          dateDepotReponse: date,
        });
    },

    async arretePC(date: string, accepte: boolean, attente: boolean) {
      if (this.permis)
        await this.update({
          id: this.permis.id,
          dateArrete: date,
          attenteFinDelaiRecoursTiers: attente,
          accepte: accepte,
        });
    },

    reset() {
      this.permis = null;
    },
  },
});

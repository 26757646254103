import { defineNuxtPlugin } from '#app/nuxt'
import { LazyWidgetAvpEnCours, LazyWidgetCommandesSurfacturees, LazyWidgetContratSigne, LazyWidgetDemandeAvp, LazyWidgetPipeCommerciale, LazyWidget1, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["WidgetAvpEnCours", LazyWidgetAvpEnCours],
["WidgetCommandesSurfacturees", LazyWidgetCommandesSurfacturees],
["WidgetContratSigne", LazyWidgetContratSigne],
["WidgetDemandeAvp", LazyWidgetDemandeAvp],
["WidgetPipeCommerciale", LazyWidgetPipeCommerciale],
["Widget1", LazyWidget1],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

<template>
  <Popover class="relative" v-slot="{ open }">
    <PopoverButton as="div" ref="popButton" @click="updatePanelBound">
      <slot></slot>
    </PopoverButton>
    <Teleport to="body">
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0">
        <div
          v-if="open"
          :style="{ left: panelLeft, top: panelTop }"
          class="fixed z-popover will-change-transform">
          <PopoverPanel static class="" ref="panel" v-slot="{ close }" :focus="true">
            <slot name="panel" :close="close" :open="open"> </slot>
          </PopoverPanel>
        </div>
      </transition>
    </Teleport>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const popButton = ref();

const popButtonEl = computed(() => popButton.value?.$el);

const popButtonRect = useElementBounding(popButtonEl);

const panel = ref();
const panelEl = computed(() => panel.value?.$el);

const panelRect = useElementBounding(panelEl);

const updatePanelBound = () => {
  nextTick(() => {
    panelRect.update();
    popButtonRect.update();
  });
};

onMounted(() => {
  document.addEventListener('filtersUpdated', updatePanelBound);
});

onUnmounted(() => {
  document.removeEventListener('filtersUpdated', updatePanelBound);
});

const panelLeft = computed(() => {
  const centerOfButton = popButtonRect.x.value + popButtonRect.width.value / 2;
  let left = centerOfButton - panelRect.width.value / 2;
  if (left < 0) {
    left = 0;
  } else if (left + panelRect.width.value > window.innerWidth) {
    left = window.innerWidth - panelRect.width.value;
  }
  return `${left}px`;
});

const panelTop = computed(() => {
  const top = popButtonRect.y.value + popButtonRect.height.value;
  if (top + panelRect.height.value > window.innerHeight) {
    return `${popButtonRect.y.value - panelRect.height.value}px`;
  }
  return `${top + 10}px`;
});
</script>

<style scoped></style>

<template>
  <NuxtLink
    :to="`/${workspace.slug}`"
    class="flex flex-shrink-0 items-center rounded-md"
    v-if="logoUrl">
    <img :src="logoUrl" class="h-full w-auto" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCurrentWorkspace } from '~/composables/workspaces/useCurrentWorkspace';
import { useInformationsMarqueStore } from '~/stores/informationsMarque';

const workspace = useCurrentWorkspace();

const infosStore = useInformationsMarqueStore();
const { infos } = storeToRefs(infosStore);

const logo = computed(() => infos.value?.logo || null);
const logoUrl = computed(() =>
  logo.value?.formats ? logo.value?.formats['thumbnail']?.url : logo.value?.url || '',
);
</script>

import { defineStore } from 'pinia';
import { useMetresStore } from './metres';
import { useDatasTablesState } from './datasTables';
import { useDeboursLigne } from '~/composables/metres/lignes/useDeboursLigne';
import { MetreStatus, ParametreKeys } from '~/types/Enums';
import { useDeboursLignesParLot } from '~/composables/metres/lignes/useDeboursLignesParLot';
import { usePvTtcLignesParLot } from '~/composables/metres/lignes/usePvTtcLignesParLot';
import { useParamValue } from '~/composables/workspaces/parametres/useParamValue';
import { usePvHtLigne } from '~/composables/metres/lignes/usePvHtLigne';
import { useEmptyLigne } from '~/composables/metres/lignes/useEmptyLigne';
import { useSortLignesByCode } from '~/composables/metres/lignes/useSortLignesByCode';
import { useSortLignesByTypeArticle } from '~/composables/metres/lignes/useSortLignesByTypeArticle';
import { useSortLignes } from '~/composables/metres/lignes/useSortLignes';
import { useSortLignesByLot } from '~/composables/metres/lignes/useSortLignesByLot';
import { useSortLignesBySousLot } from '~/composables/metres/lignes/useSortLignesBySousLot';
import { e } from 'mathjs';

export const useMetreLignesStore = defineStore({
  id: 'metre-lignes-store',
  state: () => {
    return {
      fullCollection: [],
      collection: [],
      page: 1,
      pageCount: 1,
      sortField: 'article.code',
      sortDirection: ':asc',
      query: '',
      hideEmpty: false,
      currentTableName: '',
      api: useApi().metresLignes,
      modificationQueue: [], // { code: string; propKeys: string[], idMetre: number }[]
      processingModifications: false,
    };
  },
  actions: {
    async fetchFullCollection(metre) {
      const currentMetre = ref(metre || useMetresStore().currentMetre);
      const tvaPrincipal = parseFloat(useParamValue(ParametreKeys.TAUX_TVA));
      const response = await useApi().articles.fetchAll();
      if (response?.data) {
        this.fullCollection = response.data.map((a) =>
          useEmptyLigne(a, tvaPrincipal, currentMetre.value.marge),
        );
      }
    },

    fetch(metre) {
      const currentMetre = metre || useMetresStore().currentMetre;
      this.collection = currentMetre?.lignes || [];
    },

    async create(item) {
      const res = await this.api.create(item);
      if (!res?.data) {
        return null;
      }

      item.id = res.data.id;
      item.metre = res.data.metre;
      return res.data;
    },

    addLigne(item) {
      this.collection.push(item);
    },

    async update(item) {
      const res = await this.api.update(item);
      return res?.data;
    },

    async delete(ligne, metre = null) {
      const currentMetre = metre || useMetresStore().currentMetre;
      currentMetre.lignes = currentMetre.lignes.filter((l) => l.id !== ligne.id);
      this.fetch(currentMetre);
      return await this.api.delete(ligne);
    },

    async processNextModification() {
      if (this.processingModifications) {
        return;
      }

      if (this.modificationQueue.length === 0) {
        return;
      }

      const modification = this.modificationQueue.shift();
      this.processingModifications = true;

      try {
        console.info('Traitement de la modification en cours...', modification);

        const ligne = this.displayedCollection.find((l) => l.article.code === modification.code);
        if (!ligne) {
          console.warn('Ligne non trouvée pour le code', modification.code);
          return;
        }

        if (!ligne.metre?.id && !ligne.option?.id) {
          const existingLigneReq = await useApi().metresLignes.find(1, null, null, 1, {
            metre: modification.idMetre,
            article: { code: modification.code },
          });

          if (existingLigneReq?.data.length > 0) {
            const existingLigne = existingLigneReq.data[0];

            ligne.id = existingLigne.id;
            ligne.metre = existingLigne.metre;

            await this.updateLigneValues(ligne, modification.propKeys);
          } else {
            const toSend = { ...ligne };
            delete toSend.id;
            toSend.metre = modification.idMetre;

            const createdItem = await this.create(toSend);
            if (createdItem) {
              ligne.id = createdItem.id;
              ligne.metre = createdItem.metre;

              const currentMetre = useMetresStore().currentMetre;
              if (currentMetre?.id === modification.idMetre) {
                currentMetre.lignes.push(ligne);
              }
              const metreModele = useMetreModelesStore().metre;
              if (metreModele?.id === modification.idMetre) {
                metreModele.lignes.push(ligne);
              }
            }
          }
        } else {
          await this.updateLigneValues(ligne, modification.propKeys);
        }
      } catch (error) {
        console.error('Erreur lors du traitement de la modification :', error);
        this.modificationQueue.unshift(modification);
      } finally {
        this.processingModifications = false;
        if (this.modificationQueue.length > 0) {
          this.processNextModification(); // Déclenchez le traitement du prochain élément de la file d'attente
        }
      }
    },

    async updateOrCreateAfterUserEdit(ligne, propKeys, metre = null) {
      if (!ligne || !propKeys || !Array.isArray(propKeys) || propKeys.length === 0) {
        console.warn('Paramètres invalides pour la modification.');
        return;
      }

      const currentMetre = metre || useMetresStore().currentMetre;

      this.modificationQueue.push({
        code: ligne.article.code,
        propKeys,
        idMetre: currentMetre.id,
      });

      if (!this.processingModifications) {
        this.processNextModification();
      }
    },

    async updateLigneValues(ligne, propKeys) {
      const updatedLigne = { id: ligne.id };
      propKeys.forEach((propKey) => {
        updatedLigne[propKey] = ligne[propKey];
      });

      return await this.update(updatedLigne);
    },

    async updateDescriptionNotice(ligne, value, metre = null) {
      const currentMetre = metre || useMetresStore().currentMetre;
      if (this.descriptionNoticeLigne(ligne).localeCompare(value) !== 0) {
        if (!value || value.localeCompare(ligne.article?.description_notice) === 0)
          ligne.description_notice = '';
        else ligne.description_notice = value;

        if (ligne.article?.temp) {
          await useApi().articles.update({
            id: ligne.article.id,
            description_notice: value,
            description_travaux: value,
          });
        }

        await this.updateOrCreateAfterUserEdit(ligne, ['description_notice'], currentMetre);
      }
    },

    async fixLigne(ligne) {
      if (ligne.article) {
        ligne.article_code = ligne.article.code;
        ligne.article_libelle = ligne.article.libelle;
        ligne.article_prix = ligne.article.prix;
        ligne.article_unite = ligne.article.unite;
        ligne.article_description_notice = ligne.article.description_notice;
        ligne.article_description_travaux = ligne.article.description_travaux;

        await this.update(ligne);
      }

      return ligne;
    },

    async freeLigne(ligne) {
      if (ligne.article) {
        ligne.article_code = null;
        ligne.article_libelle = null;
        ligne.article_prix = null;
        ligne.article_unite = null;
        ligne.article_description_notice = null;
        ligne.article_description_travaux = null;

        await this.update(ligne);
      }

      return ligne;
    },

    setCurrentTableName(tableName) {
      this.currentTableName = tableName;
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query) {
      this.page = 1;
      this.query = query;
    },

    resetGroupLabels() {
      useDatasTablesState(this.currentTableName).resetExpandedGroups();
    },

    resetCollection() {
      this.fullCollection = [];
      this.collection = [];
      this.hideEmpty = false;
    },

    toggleHideEmpty() {
      this.hideEmpty = !this.hideEmpty;
    },
    setLignesMarge(marge, onlyCompris = true) {
      this.displayedCollection.forEach((l) => {
        if (!onlyCompris || l.compris) {
          l.marge = marge;
        }
      });
    },
    setLignesTva(tva) {
      this.displayedCollection.forEach((l) => {
        l.tva = tva;
      });
    },
  },
  getters: {
    displayedCollection: (state) => {
      const currentMetre = useMetresStore().currentMetre;
      if (
        (!currentMetre || currentMetre.status === MetreStatus.EN_COURS) &&
        state.fullCollection.length === 0
      )
        return [];

      let result = [...state.collection, ...state.fullCollectionFiltered];

      if (state.hideEmpty || currentMetre?.status === MetreStatus.VALIDE) {
        result = result.filter((l) => l.quantite !== 0);
      }

      if (state.query) {
        result = result.filter((l) => {
          const query = state.query.toLowerCase();
          const article = l.article;
          const description = l.description_notice || article.description_notice || '';
          const code = article.code;
          const libelle = article.libelle;
          const descriptionLowerCase = description.toLowerCase();
          const codeLowerCase = code.toLowerCase();
          const libelleLowerCase = libelle.toLowerCase();
          return (
            descriptionLowerCase.includes(query) ||
            codeLowerCase.includes(query) ||
            libelleLowerCase.includes(query)
          );
        });
      }

      useSortLignes(result, [useSortLignesByTypeArticle, useSortLignesByCode], state.sortDirection);

      return result;
    },

    fullCollectionFiltered: (state) => {
      const collectionMap = new Map(state.collection.map((l) => [l.article.id, l]));
      return state.fullCollection.filter((l) => !collectionMap.has(l.article.id));
    },

    additonnalsInfos: (state) => {
      const additonnalsInfos = {};

      const { $ability } = useNuxtApp();
      const canViewDebours = $ability.can('view', 'workspace.projets.avps.metres.lignes.debours');

      const lotsLabels = state.displayedCollection.reduce((acc, l) => {
        if (l.article?.lot) {
          const lotLabel = (l.article.lot.code + ' - ' + l.article.lot.libelle)
            .toLowerCase()
            .trim();
          acc[l.article.lot.code] = lotLabel;
          const sousLotLabel = (l.article.sous_lot.code + ' - ' + l.article.sous_lot.libelle)
            .toLowerCase()
            .trim();
          acc[l.article.sous_lot.code] = sousLotLabel;
        }
        return acc;
      }, {});

      const lignes = [
        ...state.collection,
        ...state.fullCollectionFiltered.filter((l) => typeof l.id === 'number'),
      ];

      for (const lotCode of Object.keys(lotsLabels)) {
        const lotLabel = lotsLabels[lotCode];
        const debours = useDeboursLignesParLot(lignes, lotCode);
        const pvttc = usePvTtcLignesParLot(
          lignes.filter((l) => l.compris),
          lotCode,
        );
        additonnalsInfos[lotLabel] = `${
          canViewDebours
            ? '<span class="text-xs font-light">Déb. HT.</span><span>&nbsp;&nbsp;' +
              useCurrencyFormat(debours) +
              '</span><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>'
            : ''
        }${
          '<span class="text-xs font-light">Pv. TTC.</span><span>&nbsp;&nbsp;' +
          useCurrencyFormat(pvttc) +
          '</span>'
        }`;
      }

      return additonnalsInfos;
    },

    lotsInDisplayedCollection: (state) => {
      const lots = new Set();
      state.displayedCollection.forEach((l) => {
        if (l.article.lot) {
          lots.add(l.article.lot);
        }
        if (l.article.sous_lot) {
          lots.add(l.article.sous_lot);
        }
      });
      return [...lots];
    },

    descriptionNoticeLigne: (state) => (ligne) => {
      return (
        ligne.description_notice ||
        ligne.article_description_notice ||
        ligne.article?.description_notice ||
        ''
      );
    },

    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),

    isArticleActif: (state) => (article) => {
      return article && article.actif;
    },

    articlesInactifs: (state) => {
      return state.collection
        ? state.collection.filter((l) => !l.article.actif).map((l) => l.article)
        : [];
    },

    findByArticleCode: (state) => (code) => {
      return state.collection.find((l) => l.article_code === code || l.article.code === code);
    },

    jsonDataForXlsExport() {
      const result = [];

      useSortLignes(
        this.collection,
        [
          useSortLignesByLot,
          useSortLignesBySousLot,
          useSortLignesByTypeArticle,
          useSortLignesByCode,
        ],
        ':asc',
      );

      for (const ligne of this.collection) {
        if (ligne.quantite <= 0) continue;
        const row = {
          'Code article': ligne.article_cope || ligne.article.code || useConstants.NA,
          Lot: ligne.article.lot?.code || useConstants.NA,
          'Sous-lot': ligne.article.sous_lot?.code || useConstants.NA,
          Désignation: ligne.article.libelle || useConstants.NA,
          Type: ligne.article.type?.libelle || useConstants.NA,
          Gamme: ligne.article.gamme?.libelle || useConstants.NA,
          'Variante technique': ligne.article.variante_technique || useConstants.NA,
          'Description travaux':
            ligne.article.description_travaux || ligne.article.description_notice || '',
          Formule: ligne.formule_manuelle || '',
          Quantité: ligne.quantite ?? 0,
          Unité: ligne.article_unite || ligne.article.unite || useConstants.NA,
          'Prix unitaire': ligne.prix_unitaire ?? ligne.article_prix ?? ligne.article?.prix ?? 0,
          Débours: useDeboursLigne(ligne),
          Marge: ligne.marge || 0,
          'Pv HT': usePvHtLigne(ligne),
          'Taux de TVA': ligne.tva || 0,
          Compris: ligne.compris ? 'Oui' : 'Non',
        };

        result.push(row);
      }
      return result;
    },
  },
});

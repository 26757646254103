import { defineStore } from 'pinia';

import type {
  SelectedColumnsValues,
  SelectedFiltersValues,
  SelectedSortValues,
  UserFiltersConfig,
} from '~/types/models/user';
import { useCurrentUser } from '~/composables/users/useCurrentUser';
import { useCurrentWorkspace } from '~/composables/workspaces/useCurrentWorkspace';
import type { CellConfig } from '~/types/TableTypes';

// 10 pastel colors with luminecence > 0.5 and make sur all 10 are different and avoid colors near white and black
const colors = [
  '#F5B7B1',
  '#D2B4DE',
  '#AED6F1',
  '#A2D9CE',
  '#F9E79F',
  '#FADBD8',
  '#D7DBDD',
  '#F5CBA7',
  '#D5F5E3',
  '#F5DA81',
];

const createQueryFilter = (key: string, values: string) => {
  const { $_ } = useNuxtApp();
  let tempQf,
    qf = {};

  if (values) {
    let keys = key.split('.');
    let toFilterValues = [];
    if (values.includes('label#value:')) {
      const labelsValues = values.replace('label#value:', '').split('|');
      toFilterValues = labelsValues.map((labelValue) => {
        const [, value] = labelValue.split('#');
        return value;
      });
      tempQf = keys.reduceRight((acc: object, key: string) => ({ [key]: acc }), {
        $in: toFilterValues,
      });
    } else if (values.includes('range:')) {
      toFilterValues = values.replace('range:', '').split('|');
      tempQf = keys.reduceRight((acc: object, key: string) => ({ [key]: acc }), {
        $gte: toFilterValues[0],
        $lte: toFilterValues[1],
      });
    } else if (values.includes('rangenum:')) {
      toFilterValues = values.replace('rangenum:', '').split('|');
      tempQf = keys.reduceRight((acc: object, key: string) => ({ [key]: acc }), {
        $gte: toFilterValues[0] ? parseInt(values[0]) : Number.MIN_SAFE_INTEGER,
        $lte: toFilterValues[1] ? parseInt(values[1]) : Number.MAX_SAFE_INTEGER,
      });
    } else {
      toFilterValues = values.split('|');
      tempQf = keys.reduceRight((acc: object, key: string) => ({ [key]: acc }), {
        $in: toFilterValues,
      });
    }
    qf = $_.merge({}, qf, tempQf);
  }
  return qf;
};

export const useFiltersStore = defineStore('filters-store', function () {
  const { $_ } = useNuxtApp();

  const user = ref(useCurrentUser());
  const workspace = useCurrentWorkspace();

  const filters = ref<{ [tablename: string]: SelectedFiltersValues }>({});
  const columns = ref<{ [tablename: string]: SelectedColumnsValues }>({});
  const sorts = ref<{ [tablename: string]: SelectedSortValues }>({});
  const defaultColumns = ref<{ [tablename: string]: SelectedColumnsValues }>({});
  const predefinedConfigs = ref<UserFiltersConfig[]>([]);

  watch(
    filters,
    (value) => {
      for (const tableName of Object.keys(value)) {
        const tableState = useDatasTablesState(tableName);
        tableState.value.page = 1;
      }
    },
    { deep: true },
  );

  const userConfigs = computed<UserFiltersConfig[]>(() => {
    return user.value?.tables_configs ? JSON.parse(user.value.tables_configs) : [];
  });

  const currentWorkspaceSavedConfigs = computed(() => {
    return userConfigs.value.filter((c) => c.wid === workspace.value.id);
  });

  const otherWorkspacesSavedConfigs = computed(() => {
    return userConfigs.value.filter((c) => c.wid !== workspace.value.id);
  });

  const userPlusPredefinedConfigs = computed(() => {
    const predefinedConfigsFiltered = predefinedConfigs.value.filter(
      (predefinedConfig) =>
        !currentWorkspaceSavedConfigs.value.some(
          (savedConfig) => predefinedConfig.name === savedConfig.name,
        ),
    );

    return [...predefinedConfigsFiltered, ...currentWorkspaceSavedConfigs.value];
  });

  const isCurrentConfig = computed(() => {
    return (config: UserFiltersConfig) => {
      return (
        $_.isEqual(filters.value[config.tableName], config.filters) &&
        $_.isEqual(columns.value[config.tableName], config.columns) &&
        (config.sorts === undefined || $_.isEqual(sorts.value[config.tableName], config.sorts))
      );
    };
  });

  const isUserConfig = computed(() => {
    return (config: UserFiltersConfig) => {
      return !!currentWorkspaceSavedConfigs.value.find((c) => $_.isEqual(c, config));
    };
  });

  const currentConfig = computed(() => {
    return (tableName: string) =>
      currentWorkspaceSavedConfigs.value.find(
        (c) => isCurrentConfig.value(c) && c.tableName === tableName,
      );
  });

  const queryFilters = (tableName: string) => {
    const activeFilters = filters.value[tableName];
    if (!activeFilters) return {};

    const filtersKeys = Object.keys(activeFilters);

    let qf = {};

    filtersKeys.forEach((filterKey) => {
      let values = activeFilters[filterKey];
      if (filterKey.includes('|')) {
        const keys = filterKey.split('|');
        const tempQf: any = { $or: [] };
        for (let key of keys) {
          tempQf.$or.push(createQueryFilter(key, values));
        }
        qf = $_.merge({}, qf, tempQf);
      } else {
        qf = $_.merge({}, qf, createQueryFilter(filterKey, values));
      }
    });

    return qf;
  };

  const querySorts = (tableName: string) => {
    return sorts.value[tableName] || [];
  };

  const setColumnsVisible = (tableName: string, cols: CellConfig[]) => {
    columns.value[tableName] = cols
      .filter((c) => !c.hidden && c.displayPermission !== false)
      .map((c) => c.label);
  };

  const toggleColumn = (tableName: string, column: string) => {
    const cols = columns.value[tableName];
    const index = cols.indexOf(column);
    if (index >= 0) {
      cols.splice(index, 1);
    } else {
      cols.push(column);
    }
  };

  const setFilter = (tableName: string, filterKey: string, filterValue: string) => {
    if (!filterValue) {
      delete filters.value[tableName][filterKey];
    } else {
      filters.value[tableName][filterKey] = filterValue;
    }
  };

  const toggleSort = (tableName: string, sortField: string) => {
    const sortsArray = sorts.value[tableName];
    const sortIndex = sortsArray.findIndex((s) => s === sortField);
    if (sortIndex >= 0) {
      sortsArray.splice(sortIndex, 1);
    } else {
      sortsArray.push(sortField);
    }
  };

  const setSorts = (tableName: string, sortFields: string[]) => {
    sorts.value[tableName] = sortFields;
  };

  const setUserConfigs = (configs: UserFiltersConfig[]): void => {
    user.value.tables_configs = JSON.stringify([...otherWorkspacesSavedConfigs.value, ...configs]);
  };

  const addConfig = (
    name: string,
    tableName: string,
    isDefault: boolean = false,
    color: string = '',
  ): UserFiltersConfig => {
    const configs = currentWorkspaceSavedConfigs.value;

    if (isDefault) {
      configs.forEach((c) => {
        if (c.tableName === tableName) {
          c.isDefault = false;
        }
      });
    }

    let existingConfig =
      configs.find((c) => c.name === name && c.tableName === tableName) ||
      predefinedConfigs.value.find((c) => c.name === name && c.tableName === tableName);

    if (existingConfig) {
      const isPredefined = !isUserConfig.value(existingConfig);
      if (isPredefined) {
        const newConfig = { ...existingConfig };
        newConfig.filters = { ...filters.value[tableName] };
        newConfig.columns = [...columns.value[tableName]];
        newConfig.sorts = [...sorts.value[tableName]];
        newConfig.isDefault = isDefault;
        newConfig.wid = workspace.value.id;
        configs.unshift(newConfig);
      } else {
        existingConfig.filters = { ...filters.value[tableName] };
        existingConfig.columns = [...columns.value[tableName]];
        existingConfig.sorts = [...sorts.value[tableName]];
        existingConfig.isDefault = isDefault;
        existingConfig.wid = workspace.value.id;
      }
    } else {
      const lastColor = configs[configs.length - 1]?.color;
      let nextColor = color;
      if (!color) {
        if (lastColor) {
          const lastColorIndex = colors.findIndex((c) => c === lastColor);
          const nextColorIndex = lastColorIndex === colors.length - 1 ? 0 : lastColorIndex + 1;

          nextColor = colors[nextColorIndex];
        } else {
          nextColor = colors[0];
        }
      }

      configs.push({
        name,
        tableName: tableName,
        filters: { ...filters.value[tableName] },
        columns: [...columns.value[tableName]],
        sorts: [...sorts.value[tableName]],
        color: nextColor,
        isDefault,
        wid: workspace.value.id,
      });
    }
    setUserConfigs(configs);

    return configs[configs.length - 1];
  };

  const setPredefinedConfigs = (configs: UserFiltersConfig[]) => {
    predefinedConfigs.value = configs;
  };

  const removeConfig = (config: UserFiltersConfig) => {
    const configs = currentWorkspaceSavedConfigs.value;
    const configIndex = configs.findIndex(
      (c) => c.name === config.name && c.tableName === config.tableName,
    );
    if (configIndex >= 0) {
      configs.splice(configIndex, 1);
    }
    setUserConfigs(configs);
  };

  const saveUserConfigs = async () => {
    const userLight = { tables_configs: user.value.tables_configs, id: user.value.id };
    await useApi().users.update(userLight);
  };

  const setCurrentConfig = (config: UserFiltersConfig) => {
    if (isCurrentConfig.value(config)) {
      sessionStorage.removeItem('currentConfig');
      resetConfig(config.tableName);
    } else {
      sessionStorage.setItem('currentConfig', JSON.stringify(config));
      setConfig(config.tableName, config);
    }
  };

  const resetConfig = (tableName: string) => {
    filters.value[tableName] = {};
    columns.value[tableName] = [...defaultColumns.value[tableName]];
    sorts.value[tableName] = [];
  };

  const setConfig = (tableName: string, config: UserFiltersConfig) => {
    filters.value[tableName] = { ...config.filters };
    columns.value[tableName] = [...config.columns];
    sorts.value[tableName] = config.sorts ? [...(config.sorts || [])] : [];
  };

  const setDefaultConfig = (tableName: string, defaultCols: string[]) => {
    defaultColumns.value[tableName] = [...defaultCols];
    const config = currentWorkspaceSavedConfigs.value.find(
      (c) => c.tableName === tableName && c.isDefault,
    );

    if (config) {
      setConfig(tableName, config);
    } else {
      const currentSessionConfig = sessionStorage.getItem('currentConfig');

      const localConfig = currentSessionConfig ? JSON.parse(currentSessionConfig) : null;
      if (localConfig && localConfig.tableName === tableName) {
        setConfig(tableName, localConfig);
      } else {
        resetConfig(tableName);
      }
    }
  };

  return {
    filters,
    columns,
    sorts,
    defaultColumns,
    predefinedConfigs,
    user,
    currentWorkspaceSavedConfigs,
    userPlusPredefinedConfigs,
    isCurrentConfig,
    isUserConfig,
    currentConfig,
    queryFilters,
    setColumnsVisible,
    toggleColumn,
    setFilter,
    setUserConfigs,
    addConfig,
    setPredefinedConfigs,
    removeConfig,
    saveUserConfigs,
    setCurrentConfig,
    resetConfig,
    setConfig,
    setDefaultConfig,
    toggleSort,
    setSorts,
    querySorts,
  };
});

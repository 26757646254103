import { defineStore } from 'pinia';

export const useStatutsStore = defineStore({
  id: 'statuts-store',
  state: () => {
    return {
      collection: [],
      api: useApi().statuts,
    };
  },
  actions: {
    async fetch() {
      if (this.collection?.length) return;
      const response = await this.api.find(1, '', '', 999);
      if (response?.data) this.collection = response.data;
      return this.collection;
    },
  },
  getters: {
    getStatus: (state) => (code) => {
      return state.collection?.find((statut) => statut.code === code) || null;
    },
    getAvancement: (state) => (code) => {
      for (const statut of state.collection) {
        const avancement = statut.avancements?.find((a) => a.code === code);
        if (avancement) return avancement;
      }
    },
    sortedStatuts: (state) => {
      return state.collection.sort((a, b) => a.ordre - b.ordre);
    },
    allAvancements: (state) => {
      const avancements = [];
      for (const statut of state.sortedStatuts) {
        avancements.push(...statut.avancements.sort((a, b) => a.ordre - b.ordre));
      }
      return avancements;
    },
  },
});

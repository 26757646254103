import { ParametreKeys } from '~/types/Enums';
import { useParametresStore } from '~~/stores/parametres';

export const useApplyTva = (prix: number, taux?: number) => {
  const tauxTVA = taux !== undefined && taux !== null ? taux : useParametresStore().tauxTva;
  return prix * tauxTVA;
};

export const useUnapplyTva = (prix: number, taux?: number) => {
  const tauxTVA = taux || useParametresStore().tauxTva;
  if (tauxTVA === 0) return prix;
  return prix / tauxTVA;
};

export const useCoefMarge = (margePercent: number) => {
  const marge = margePercent / 100;
  if (marge === 1) return 1;
  return marge / (1 - marge);
};

export const usePrixVente = (debours: number, margePercent: number) => {
  return debours * (1 + useCoefMarge(margePercent));
};

export const useRound = (prix: number, nbDecimal?: number) => {
  const defaultNbDecimal = 2;
  const decimalFactor = Math.pow(10, nbDecimal ?? defaultNbDecimal);
  const result = Math.round(prix * decimalFactor) / decimalFactor;

  //const result = evenRound(prix, nbDecimal || defaultNbDecimal);
  return result;
};

export const useTauxTva = (tvaPercent?: number) => {
  const percent =
    tvaPercent !== undefined && tvaPercent !== null ? tvaPercent : useTauxTvaPercent();
  return percent * 0.01 + 1;
};

export const useTauxTvaPercent = () => {
  return parseFloat(useParametresStore().paramByKey(ParametreKeys.TAUX_TVA).valeur);
};

export const useMontantTva = (prix: number, taux?: number) => {
  const t = taux || useTauxTva();
  const p = prix * (t - (t >= 1 ? 1 : 0));

  return Math.round(p * 100) / 100;
};

export const useCalcMarge = (pv: number, debours: number, decimals?: number) => {
  return useRound(pv === 0 ? (debours === 0 ? 0 : -100) : (1 - debours / pv) * 100, decimals ?? 2);
};

export const usePercentVariationBetween = (a: number, b: number) => {
  return ((b - a) / a) * 100;
};

export const evenRound = (num: number, decimalPlaces: number) => {
  const d = decimalPlaces || 2;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

<template>
  <ModalsContainer :open="open" @close="onClose">
    <template #title> {{ title }} </template>

    <template #left>
      <ModalsIcon :icon="QuestionMarkCircleIcon" />
    </template>

    <template #content>
      <p class="text-sm" v-html="message"></p>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button
          v-for="option in options"
          :key="option.label"
          :variant="option.variant || 'primary'"
          :disabled="oneActionPending"
          :loading="optionPendings[option.label]"
          @click.stop="optionCallback(option)">
          {{ option.label }}
        </Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import type { BtnVariant } from '../button/button.vue';

export type ChoicesOption = {
  label: string;
  variant?: BtnVariant;
  callback: () => void;
};

const open = ref(true);

type Props = {
  title: string;
  message: string;
  options: ChoicesOption[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const optionPendings = ref<{ [key: string]: boolean }>({});

watch(
  () => props.options,
  (options) => {
    options.forEach((option) => {
      if (!optionPendings.value[option.label]) {
        optionPendings.value[option.label] = false;
      }
    });
  },
  { immediate: true },
);

const oneActionPending = computed(() => {
  return Object.values(optionPendings.value).some((pending) => pending);
});

const optionCallback = async (option: ChoicesOption) => {
  if (optionPendings.value[option.label]) return;
  optionPendings.value[option.label] = true;

  await option.callback();

  optionPendings.value[option.label] = false;
  open.value = false;
};

const onClose = () => {
  open.value = false;
  emit('close');
};
</script>

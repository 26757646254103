<template>
  <div class="theme-default h-screen w-screen text-skin-base">
    <NuxtLoadingIndicator color="#E88A1A" />
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import type { NuxtAppManifestMeta } from '#app';

useHead({
  bodyAttrs: {
    class: 'theme-default',
  },
});

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = useDebounceFn(callback, 20);
    super(callback);
  }
};

const nuxtApp = useNuxtApp();
nuxtApp.hook('app:manifest:update', (meta?: NuxtAppManifestMeta) => {
  reloadNuxtApp();
});
</script>

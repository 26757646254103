import { CurrencyEuroIcon, HomeIcon, PresentationChartLineIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';

export const useGesfiStore = defineStore('nav-gesfi', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<any>;

      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-gesfi',
          label: 'Gestion financière',
          redirectToChildren: true,
          icon: CurrencyEuroIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.gesfi'],
          children: [
            {
              name: 'workspace-gesfi-achats',
              label: 'Achats',
              redirectToChildren: true,
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.gesfi.achats'],
              children: [
                {
                  name: 'workspace-gesfi-achats-factures',
                  label: 'Factures',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.gesfi.achats.factures'],
                },
                {
                  name: 'workspace-gesfi-achats-reglements',
                  label: 'Règlements',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.gesfi.achats.reglements'],
                },
                {
                  name: 'workspace-gesfi-achats-commandes',
                  label: 'Commandes',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.gesfi.achats.commandes'],
                },
              ],
            },
            {
              name: 'workspace-gesfi-ventes',
              redirectToChildren: true,
              params: { workspace: workspace.value?.slug },
              label: 'Ventes',
              permission: ['view', 'workspace.gesfi.ventes'],
              children: [
                {
                  name: 'workspace-gesfi-ventes-suivifactu',
                  params: { workspace: workspace.value?.slug },
                  label: 'Suivi facturation',
                  permission: ['view', 'workspace.gesfi.ventes.suiviFactu'],
                },
              ],
            },
            {
              name: 'workspace-gesfi-dashboards',
              redirectToChildren: true,
              params: { workspace: workspace.value?.slug },
              label: "Suivi de l'activité",
              permission: ['view', 'workspace.gesfi.dashboards'],
              children: [
                {
                  name: 'workspace-gesfi-dashboards-facturation',
                  label: 'Facturation',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.gesfi.dashboards.facturation'],
                },
                {
                  name: 'workspace-gesfi-dashboards-ventes',
                  label: 'Ventes',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.gesfi.dashboards.ventes'],
                },
              ],
            },
            {
              name: 'workspace-gesfi-exports',
              params: { workspace: workspace.value?.slug },
              label: 'Export des données',
              permission: ['view', 'workspace.gesfi.export'],
            },
          ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});

<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> Date de signature du contrat </template>
    <template #subtitle> Saisir la date de signature du contrat </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <InputText type="date" v-model="theDate" id="date-pres" required />
        <InputToggle label="Acompte à la signature ?" v-model="isAcompte" />
        <FormInputContainer label="Montant (€)" v-if="isAcompte">
          <CurrencyInput :options="{ currency: 'EUR' }" v-model="acompte" id="acompte" />
        </FormInputContainer>
        <FormInputContainer label="Pv TTC Contrat (€)">
          <InputErrorWrapper :error-message="pvErrorMesssage">
            <CurrencyInput
              :options="{ currency: 'EUR' }"
              v-model="pvTtcContrat"
              id="pvTtcContrat"
              required
              @focus="onPvInputFocus"
              @blur="onPvInputBlur" />
          </InputErrorWrapper>
        </FormInputContainer>
        <Grid size="1" v-if="!isEditing">
          <GridCell>
            <FormInputContainer label="Délai de réalisation des conditions suspensives (mois)">
              <InputText type="number" v-model="dureeConditions" id="dureeConditions" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer label="Délai de réalisation des travaux (mois)">
              <InputText type="number" v-model="dureeTravaux" id="dureeTravaux" />
            </FormInputContainer>
          </GridCell>
        </Grid>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending || !canEnregistrer" :loading="pending"
          >Enregistrer</Button
        >
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'vue-toastification';
import { useCurrentProjet } from '~/composables/projets/useCurrentProjet';

const open = ref(true);

type Props = {
  currentDate: string;
  currentAcompte: number;
  callback: (
    date: string,
    acompte: number,
    dureeConditions?: number,
    dureeTravaux?: number,
  ) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const isEditing = ref(!!props.currentDate);

const pending = ref(false);

const isAcompte = ref(props.currentAcompte > 0);

const theDate = ref(useDateFormat(props.currentDate, 'yyyy-MM-dd'));
const acompte = ref(props.currentAcompte);
const dureeConditions = ref(2);
const dureeTravaux = ref(8);

const onValidate = async () => {
  if (!canEnregistrer.value) return;
  pending.value = true;
  await props.callback(theDate.value, acompte.value, dureeConditions.value, dureeTravaux.value);
  pending.value = false;
  open.value = false;
};

const cancel = () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});

const currentProjet = useCurrentProjet();
const lastAvp = currentProjet.value?.avps?.[0];
const currentMetre = lastAvp?.metre;
const metreId = currentMetre?.id;

if (!metreId) {
  useToast().error('Impossible de trouver le métré associé au projet');
  cancel();
}

const { data: matriceTvaMetre } = useAsyncData(
  'matriceTvaMetre',
  async () => await useApi().metres.getMatriceTva(metreId!),
  { default: () => [] },
);

const pvTtcMetre = computed(() => {
  return useRound(
    matriceTvaMetre.value?.reduce((acc, { tva, pv }) => {
      acc += useApplyTva(pv, useTauxTva(tva));
      return acc;
    }, 0) || 0,
  );
});

const pvTtcContrat = ref(0);

const typingPvTtcContrat = ref(false);

const onPvInputFocus = () => {
  typingPvTtcContrat.value = true;
};

const onPvInputBlur = () => {
  typingPvTtcContrat.value = false;
};

const pvErrorMesssage = computed(() => {
  if (pvTtcContrat.value === 0) return '';
  if (typingPvTtcContrat.value) return '';
  if (!pvSaisiIsEqualToPvMetre.value)
    return 'Le montant est différent du prix de Vente TTC dans le métré. Veuillez au préalable corriger le métré pour que le prix de vente TTC soit correct';
  return '';
});

const pvSaisiIsEqualToPvMetre = computed(() => {
  return Math.abs(useRound(pvTtcContrat.value - pvTtcMetre.value)) <= 0.01;
});

const canEnregistrer = computed(() => {
  return pvSaisiIsEqualToPvMetre.value && theDate.value;
});
</script>

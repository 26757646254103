import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'informations-marque';

const populate = { logo: true };

const searchFields: string[] = [];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate);

  return theApi;
};

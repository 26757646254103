<template>
  <button
    :type="type"
    class="relative flex cursor-pointer items-center justify-center gap-3 rounded-md px-4 py-2 text-sm !ring-0 transition-colors hover:bg-opacity-80 focus:outline-skin-accent disabled:cursor-not-allowed disabled:border-opacity-30 disabled:bg-opacity-30"
    :class="[{ '!gap-2 !px-2 !py-1 !text-xs': small }, classObject.value]"
    :disabled="loading">
    <div
      class="flex items-center"
      :class="[gapClass, { uppercase: uppercase, 'opacity-0': loading }]">
      <div
        v-if="icon || $slots.icon"
        :class="{
          'order-1': iconPosition === 'before',
          'order-2': iconPosition !== 'before',
        }">
        <slot name="icon">
          <component
            :is="icon"
            class="w-[1em] text-current"
            :class="{ 'text-base': !small, '!w-5': variant === 'action' }" />
        </slot>
      </div>
      <span
        v-if="$slots.default"
        class="whitespace-nowrap font-normal"
        :class="{
          'order-2': iconPosition === 'before',
          'order-1': iconPosition !== 'before',
        }">
        <slot />
      </span>
    </div>
    <div
      class="pointer-events-none absolute inset-0 flex h-full items-center justify-center opacity-0"
      :class="{ '!opacity-100': loading }">
      <spinner class="h-[70%] text-skin-heading" />
    </div>
  </button>
</template>

<script setup lang="ts">
import { type Component, type Slot } from 'vue';

export type BtnVariant = 'primary' | 'danger' | 'muted' | 'muted_danger' | 'action' | 'secondary';

const styles: Record<string, any> = {
  primary: ref('border border-transparent bg-skin-fill-11 text-skin-inverted'),
  danger: ref(
    'border border-transparent bg-skin-button-danger text-skin-inverted focus:bg-skin-button-danger',
  ),
  muted: ref(
    'bg-transparent text-skin-base hover:text-opacity-80 focus:bg-transparent focus:text-opacity-80 disabled:text-opacity-60',
  ),
  muted_danger: ref(
    'bg-transparent text-skin-danger hover:text-opacity-80 focus:bg-transparent focus:text-opacity-80 disabled:text-opacity-60 underline py-0',
  ),
  action: ref(
    'bg-transparent text-skin-heading hover:text-opacity-80 focus:bg-transparent focus:text-opacity-80 disabled:text-opacity-60 py-0 px-0',
  ),
  secondary: ref(
    'border border-skin-heading disabled:text-opacity-60 bg-skin-fill text-skin-heading',
  ),
};

const classObject = computed(() => styles[props.variant]);

type Props = {
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: Component | string;
  iconPosition?: 'before' | 'after';
  variant?: BtnVariant;
  small?: boolean;
  uppercase?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  type: 'button',
  iconPosition: 'before',
  variant: 'primary',
  small: false,
});

const slots = useSlots();

const gapClass = computed(() => {
  if (props.icon && hasSlotContent(slots.default)) {
    return props.small ? 'gap-2' : 'gap-3';
  }
  return '';
});

// Adapted from https://github.com/vuejs/vue-next/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77
const isVnodeEmpty = (vnodes: Array<VNode>) => {
  return vnodes.every((node: VNode) => {
    if (typeof node.children === 'string' && !node.children.trim()) {
      return true;
    }

    return false;
  });
};

const hasSlotContent = (slot: Slot<any> | undefined) => {
  if (!slot) {
    return false;
  }
  return !isVnodeEmpty(slot());
};
</script>
